import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { UsuarioService } from 'src/app/services/usuario.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent {


  public usuario: User;

  constructor( private usuarioService: UsuarioService,
               private router: Router ) {
    this.usuario = usuarioService.usuario;
  }

  buscarTodo( termino: string) {
    if ( termino.length === 0 ) {
      return this.router.navigateByUrl('/dashboard');
    }
    this.router.navigateByUrl(`/dashboard/buscar/${ termino }`);
  }
  logout() {
    this.usuarioService.logout();
  }


}
