<header class="topbar" style="height: 60px;">
  <nav class="navbar navbar-expand-md navbar-light" style="padding: 0; display: flex; align-items: center; justify-content: space-between; height: 60px;">

    <!-- Columna Izquierda: Logo (20%) -->
    <div class="navbar-header" style="flex-basis: 15%; display: flex; align-items: center;">
      <!-- <a class="navbar-brand" routerLink="./uco" *ngIf="usuario.role == 'DRIVER_ROLE'">
        <img src="./assets/images/kite-brand.png" alt="homepage" class="img-fluid" style="max-height: 50px;" />
      </a>
      <a class="navbar-brand" routerLink="./" *ngIf="usuario?.role != 'DRIVER_ROLE'">
        <img src="./assets/images/kite-brand.png" alt="homepage" class="img-fluid" style="max-height: 50px;" />
      </a> -->
    </div>

    <div class="col-md-8">
      <app-nav-bar></app-nav-bar>
    </div>


    <!-- Columna Derecha: Perfil de usuario (20%) -->
    <ul class="navbar-nav" style="flex-basis: 15%; display: flex; justify-content: flex-end; align-items: center;">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
          <img [src]="usuario.img | imagen: 'users'" alt="user" class="profile-pic" style="max-height: 40px;" />
        </a>
        <div class="dropdown-menu dropdown-menu-right animated flipInY">
          <div class="dw-user-box">
            <div class="u-img">
              <img [src]="usuario.img | imagen: 'users'" alt="user" class="img-fluid" style="max-height: 40px;" />
            </div>
            <div class="u-text">
              <h4>{{usuario.name}}</h4>
              <p class="text-muted">{{usuario.email | slice:0:20}}{{(usuario.email.length > 20) ? '...' : ''}}</p>
              <a routerLink="./perfil" class="btn btn-rounded btn-danger btn-sm">View Profile</a>
            </div>
          </div>
          <a routerLink="./perfil" class="dropdown-item"><i class="ti-user"></i> My Profile</a>
          <div class="dropdown-divider"></div>
          <a (click)="logout()" class="dropdown-item"><i class="fa fa-power-off"></i> Logout</a>
        </div>
      </li>
    </ul>
  </nav>
</header>
