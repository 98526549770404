import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';

import { ModalImagenService } from 'src/app/services/modal-imagen.service';
import { ImageUploadService } from '../../../services/files/img-upload.service';


@Component({
  selector: 'app-modal-imagen',
  templateUrl: './modal-imagen.component.html',
  styles: [
  ]
})
export class ModalImagenComponent implements OnInit {

  @ViewChild('inputfile') inputfile: ElementRef;

  public imagenSubir: File;
  public imgTemp: any = null;


  constructor( public modalImagenService: ModalImagenService,
               public imageUploadService: ImageUploadService ) { }

  ngOnInit(): void {
  }


  cerrarModal() {
    this.imgTemp = null;
    this.modalImagenService.cerrarModal();
    this.inputfile.nativeElement.value = '';
  }




cambiarImagen(file: File) {

  this.imagenSubir = file;

  if ( !file ) {
     return this.imgTemp = null;
     }


  const reader = new FileReader();
  reader.readAsDataURL( file );

  reader.onloadend = () => {

    this.imgTemp = reader.result;

  };

}

subirImagen() {

  const id = this.modalImagenService.id;
  const tipo = this.modalImagenService.tipo;

  this.imageUploadService.updateImage( this.imagenSubir, tipo, id)
                         .then(img => {

                           let tipoTitleCase = tipo.charAt(0).toUpperCase() + tipo.substring(1).toLowerCase();

                           Swal.fire('Upload Image', tipoTitleCase + ' Image Updated', 'success');

                           this.modalImagenService.nuevaImagen.emit( img );

                           this.inputfile.nativeElement.value = '';

                           this.cerrarModal();

                        }, (err) => {

                          Swal.fire('Error', 'Upload Image Error', 'error');

                        });


}


}
