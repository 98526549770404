import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { RegisterComponent } from './view/register/register.component';
import { LoginComponent } from './view/login/login.component';
import { RecoverypasswordComponent } from './view/recoverypassword/recoverypassword.component';



const routes: Routes = [
  { path: 'register', component: RegisterComponent},
  { path: 'login', component: LoginComponent},
  { path: 'recoverypassword', component: RecoverypasswordComponent}


];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule]
})

export class AuthRoutingModule {}
