import { environment } from '../../environments/environment';

const base_url = environment.base_url;


export class User {
  constructor(
    public name: string,
    public email: string,
    public password?: string,
    public img?: string,
    public google?: boolean,
    public role?: 'ADMIN_ROLE' | 'USER_ROLE' | 'SERVMAN_ROLE' | 'LOGICOR_ROLE' | 'SALESMAN_ROLE' | 'SALESPER_ROLE' | 'INSLLTEAM_ROLE' | 'DRIVER_ROLE',
    public uid?: string,
    public clients?: any,
    public signature?: string,
    public signature_date?: string,
     public status?: string,
     public active?: string,
     public year?: string,
     public month?: string,
     public day?: string,
     public created_at?: string,
     public updated_at?: string,
  ) {}


  get imagenUrl() {
    if ( !this.img ) {
      return `${ base_url }/upload/usuarios/no-image`;
    }else if (this.img.includes('https')) {
      return this.img;
    }else if (this.img) {
      return `${ base_url }/upload/usuarios/${ this.img }`;
    } else {
      return `${ base_url }/upload/usuarios/no-image`;
    }
  }
}
