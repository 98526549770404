<div class="fondo-modal-imagen animate fadeIn fast" [class.hidde]="modalImagenService.ocultarModal">
    <div class="modal fade show d-block" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel1">Upload Image</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrarModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <h3>Select Image</h3>

                    <div class="text-center mb-3">
                        <img *ngIf="!imgTemp" [src]="modalImagenService.img" width="200">
                        <img *ngIf="imgTemp" [src]="imgTemp" width="200">

                    </div>


                    <input #inputfile type="file" (change)="cambiarImagen($event.target.files[0])">

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cerrarModal()">Close</button>
                    <button type="button" class="btn btn-primary" (click)="subirImagen()">Save Image</button>
                </div>
            </div>
        </div>
    </div>
</div>