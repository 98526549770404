import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { UsuarioService } from '../services/usuario.service';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss']
})
export class LayoutContainerComponent implements OnInit, OnDestroy {

  timeLogout: number;
  timePopup: number;

  subscription: Subscription;

  constructor( private usuarioService: UsuarioService ) { }

  ngOnInit(): void {
   this.closeSessionBy(600000);
  }

 ngOnDestroy(): void {
     this.resetTimers();
     this.subscription.unsubscribe();
  }

  closeSessionBy(miliseconds: number): void {
    this.subscription = fromEvent(document, 'mousemove')
                                  .subscribe(_ => {
                                      this.resetTimers();
                                      this.timeLogout = setTimeout( () => {
                                        this.usuarioService.logout();
                                      }, miliseconds);
                                      this.timePopup = setTimeout( () => {
                                       this.triggerNotification();
                                      }, miliseconds - 30000);
                                 });
  }

triggerNotification(): void {
 Swal.fire({
            position: 'top-end',
            icon: 'warning',
            text: 'Your session will be logged out',
            showConfirmButton: false,
            timer: 30000
          })
}

resetTimers(): void {
   Swal.close();
   clearTimeout( this.timeLogout );
   clearTimeout( this.timePopup );
}

}
