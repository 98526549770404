import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { AuthInteractor } from '../interactor/auth.interactor';
import { ApiResult } from './interfaces/api.result';
import { ApiRequest } from './interfaces/api.request';

import { User } from '../../models/user.model';
import { LoginForm } from 'src/app/interfaces/login-form.interface';
import { IsRequestComplete } from '../../interfaces/isrequest-complete';
import { EmailPwd } from 'src/app/constants/forms/recovery-password';



@Injectable({
  providedIn: 'root'
})
export class AuthPresenter implements ApiResult, IsRequestComplete, ApiRequest {

private apiResult: ApiResult;
private _isRequestComplete: IsRequestComplete;


constructor(private interactor: AuthInteractor){
interactor.setPresenter(this);
}

setRequestCompleteComponent(isRequestComplete: IsRequestComplete){
this._isRequestComplete = isRequestComplete;
}

setComponent(result: ApiResult){
this.apiResult = result;
}


//---------------
isRequestComplete(status: boolean, error?: HttpErrorResponse, _data?: Object): void {
  this._isRequestComplete.isRequestComplete(status, error, _data);
}

isLoginByGoogle(status: boolean, error?: HttpErrorResponse): void {
   this.apiResult.isLoginByGoogle(status, error);
}

isRecoverKey(status: boolean, error?: HttpErrorResponse): void {
  this.apiResult.isRecoverKey(status, error);
}

isSendRecoveryKey(res: object, error?: HttpErrorResponse): void {
  this.apiResult.isSendRecoveryKey(res, error);
}



// -------------
newUser(user: User):void{
this.interactor.newUser(user);
}

//--------------

login(formData: LoginForm){
this.interactor.login(formData);
}

googleInit(): Promise<any>{
 return this.interactor.googleInit();
}
loginGoogle(token){
 this.interactor.loginGoogle(token);
}

// -------------
sendRecoveryKey(data: EmailPwd): void {
  this.interactor.sendRecoveryKey(data);
}
recoverKey(email: string): void {
  this.interactor.recoverKey(email);
}
updatePaswordUser(data: any): void {
  this.interactor.updatePaswordUser(data);
}


}
