import { UsuarioService } from '../../services/usuario.service';
import { User } from '../../models/user.model';
import { Injectable } from '@angular/core';
import { AuthPresenter } from '../presenter/auth.presenter';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginForm } from 'src/app/interfaces/login-form.interface';
import { EmailPwd } from '../../constants/forms/recovery-password';


@Injectable({
  providedIn: 'root'
})
export class AuthInteractor {

private _presenter: AuthPresenter;


constructor(private usuarioService: UsuarioService){}

setPresenter(presenter: AuthPresenter):void{
this._presenter = presenter;
}


newUser(user: User): void{
  this.usuarioService.add(user)
              .subscribe(
                   (resp: any) => {
                   this._presenter.isRequestComplete(resp.ok);
                   this.usuarioService.cargarLocalStorage(resp.token, '');
                  },
                  (err: HttpErrorResponse) => this._presenter.isRequestComplete(false, err)
                );
}

login(formData: LoginForm){
  this.usuarioService.login(formData)
                    .subscribe(
                          (resp: any) => this._presenter.isRequestComplete(resp.ok),
                          (err: HttpErrorResponse) => this._presenter.isRequestComplete(false, err)
                    );
}

googleInit(): Promise<any>{
  return this.usuarioService.googleInit();
}

loginGoogle(token){
  this.usuarioService.loginGoogle(token)
                      .subscribe(
                          (resp: any) => this._presenter.isLoginByGoogle(resp.ok),
                          (err: HttpErrorResponse) => this._presenter.isRequestComplete(false, err)
                      );
}

sendRecoveryKey(data: EmailPwd): void{
  this.usuarioService.sendRecoveryKey(data)
              .subscribe(
                   resp => this._presenter.isSendRecoveryKey(resp),
                  (err: HttpErrorResponse) => this._presenter.isSendRecoveryKey(null, err)
                );
}
recoverKey(email: string): void{
  this.usuarioService.recoverKey(email)
              .subscribe(
                   (resp: any) => this._presenter.isRecoverKey(resp.ok),
                   (err: HttpErrorResponse) => this._presenter.isRecoverKey(false, err)
                );
}
updatePaswordUser(data): void{
  this.usuarioService.updatePaswordUser(data)
              .subscribe(
                   (resp: any) => this._presenter.isRequestComplete(resp.ok, null, resp),
                   (err: HttpErrorResponse) => this._presenter.isRequestComplete(false, err)
                );
}

}
