<nav class="navbar navbar-expand-lg navbar-light kite-navbar">
  <div class="container-fluid kite-conteiner" #navContainer>
    <!-- Home button -->
    <a class="btn menu-btn" [routerLink]="['/dashboard']" [ngClass]="{'active-btn': isRouteActive('/dashboard')}">
      <i class="fa fa-home"></i> Home
    </a>

    <button class="navbar-toggler" type="button" (click)="toggleMenu()"
            [attr.aria-expanded]="isMenuOpen" aria-controls="navbarNav">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [class.show]="isMenuOpen" id="navbarNav">
      <ul class="navbar-nav" #navList>
        <!-- Botones visibles -->
        <li class="nav-item" *ngFor="let item of visibleMenuItems">
          <a class="btn menu-btn" [routerLink]="item.route" [ngClass]="{'active-btn': isRouteActive(item.route)}">
            <i [class]="item.icon"></i> {{item.label}}
          </a>
        </li>

        <!-- Botón More -->
        <li class="nav-item" *ngIf="showMoreButton">
          <div class="more-menu">
            <button class="btn menu-btn" (click)="toggleMoreMenu($event)">
              ••• More
            </button>
            <div class="more-dropdown" [class.show]="isMoreMenuOpen">
              <a class="menu-btn dropdown-item"
                 *ngFor="let item of hiddenMenuItems"
                 [routerLink]="item.route"
                 [ngClass]="{'active-btn': isRouteActive(item.route)}">
                <i [class]="item.icon"></i> {{item.label}}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
