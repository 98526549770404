import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../guards/auth.guard';
import { LayoutContainerComponent } from '../layout/layout-container.component';
import { MegaMenuComponent } from './core/mega-menu/mega-menu.component';


const routes: Routes = [
{
   path: 'dashboard',
  component: LayoutContainerComponent,
  canActivate: [ AuthGuard ],
  canLoad: [ AuthGuard ],
  loadChildren: () => import('./child-routes.module').then(m => m.ChildRoutesModule)

}

];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule]
})

export class PagesRoutingModule {}
