import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { VerticalLayoutComponent } from './layout/layout.component';
import { SharedModule } from '../shared/shared.module';
import { WidgetsModule } from '../../shared/widgets.module';
import { KiteModule } from 'src/app/shared/widgets/kite/kite.module';

@NgModule({
  declarations: [
    VerticalLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    WidgetsModule,
    KiteModule
  ],
  exports: [
    VerticalLayoutComponent
  ]
})
export class VerticalModule { }
