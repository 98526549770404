import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

import { RegisterUserField } from '../../../constants/forms/register-user.fields';
import { ApiRequest } from '../../presenter/interfaces/api.request';
import { AuthPresenter } from '../../presenter/auth.presenter';
import { IsRequestComplete } from '../../../interfaces/isrequest-complete';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [ './register.component.css' ],
})
export class RegisterComponent implements IsRequestComplete, OnInit {

private request: ApiRequest;

// variables
public formSubmitted = false;

// formulario
public registerForm: FormGroup;



// ------------------------Constructor------------------------------------
  constructor( private fb: FormBuilder,
               private router: Router,
               private authPresenter: AuthPresenter,
               ) {

this.request = authPresenter;
authPresenter.setRequestCompleteComponent(this);

 }




ngOnInit(): void {
this.initRegisterForm();
}

isRequestComplete(status: boolean, error?: HttpErrorResponse): void {
    (status)?  this.router.navigateByUrl('/login') : Swal.fire('Error', error.error.msg, 'error');
}


// -----------Metodos-------------------
initRegisterForm(){
this.registerForm = this.fb.group(RegisterUserField, {
  validators: this.passwordIguales('password', 'password2') // validador Personalizado
});
}

crearUsuario() {

  this.formSubmitted = true;
  if ( this.registerForm.invalid ) {
      return;
  }
  this.request.newUser(this.registerForm.value);
}



// metodo para validar los campos del formulario
campoNoValido( campo: string): boolean {

  // valida el campo y boton de login
  if (this.registerForm.get(campo).invalid && this.formSubmitted) {
    return true;
  } else {
    return false;
  }

}

contrasenasNoValidas() {

  const pass1 = this.registerForm.get('password').value;
  const pass2 = this.registerForm.get('password2').value;

  // valida si son iguales y boton del login
  if ( (pass1 !== pass2) && this.formSubmitted ) {
    return true;
  } else {
    return false;
  }

}


aceptarTerminos() {
return !this.registerForm.get('terminos').value && this.formSubmitted;
}


// metodo que funciona como un validador personalizado
passwordIguales( pass1Name: string, pass2Name: string ) {

  // retorna una función
  return ( formGroup: FormGroup ) => {

    const pass1Control = formGroup.get(pass1Name);
    const pass2Control = formGroup.get(pass2Name);

    if ( pass1Control.value === pass2Control.value) {
      pass2Control.setErrors(null);
    } else {
      pass2Control.setErrors({ noEsIgual: true }); // es un error que se le asigna al control
                                                    // para que no sea valido el formulario
    }

  };



}




} // END class
