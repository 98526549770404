import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

import { LoginUserField } from 'src/app/constants/forms/login-user.fields';
import { HttpErrorResponse } from '@angular/common/http';

import { AuthPresenter } from '../../presenter/auth.presenter';
import { ApiRequest } from '../../presenter/interfaces/api.request';
import { ApiResult } from '../../presenter/interfaces/api.result';
import { IsRequestComplete } from 'src/app/interfaces/isrequest-complete';

declare const gapi: any; // Declaracion del Google Sign-in

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.css' ]
})

export class LoginComponent implements ApiResult, IsRequestComplete, OnInit {

private request: ApiRequest;

  // variables
public formSubmitted = false;
public auth2: any; // referencia de Google Sign-in

// formulario
public loginForm: FormGroup;




  // ----------------------Constructor--------------------------------
  constructor( private fb: FormBuilder,
               private router: Router,
               private authPresenter: AuthPresenter,
               private ngZone: NgZone) {

this.request = authPresenter;
authPresenter.setComponent(this);
authPresenter.setRequestCompleteComponent(this);

 }


  ngOnInit(): void {
  // this.renderButton(); // llama el metodo para usar el Boton de Google
   this.initLoginForm();
  }


  isRequestComplete(status: boolean, error?: HttpErrorResponse): void {
     (status)?  this.router.navigateByUrl('/') : Swal.fire('Error', error.error.msg, 'error');
  }

  isLoginByGoogle(status: boolean, error?: HttpErrorResponse): void {
     if(status){
         // NgZone es usado para ejecutar funciones con librerias de terceros por fuera de Angular
                              this.ngZone.run( () => {

                                // Navegando al Dasahboard
                               this.router.navigateByUrl('/');

                              });
      }else{
     alert(JSON.stringify(error, undefined, 2));
    }
    }


// -----------Metodos-------------------
initLoginForm(){
this.loginForm = this.fb.group(LoginUserField);
}

login() {

 this.loginForm.get('remember').value ?
  localStorage.setItem('email', this.loginForm.get('email').value):
  localStorage.removeItem('email');

  this.request.login(this.loginForm.value);

  }



  // -------------------------------GOOGLE SIGN-IN-------------------------------------------------
  // el codigo se modifica completo para que funcione correctamente en Angular
  // Implementacion del Google Sign-In, en el servicio de usuario se inicializa
 renderButton() {
    gapi.signin2.render('my-signin2', {
      scope: 'profile email',
      width: 240,
      height: 50,
      longtitle: true,
      theme: 'dark'
    });


    this.startApp();

  }


async  startApp() {
     this.auth2 = await this.request.googleInit().then(res => res);
    this.attachSignin(document.getElementById( 'my-signin2' ));
  }




attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
        ( googleUser ) => {
         const id_token = googleUser.getAuthResponse().id_token;
        this.request.loginGoogle(id_token);
        });
  }

} // END class
