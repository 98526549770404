import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutContainerComponent } from './layout-container.component';
import { VerticalModule } from './vertical/vertical.module';
import { SharedModule } from './shared/shared.module';
import { PipesModule } from '../pipes/pipes.module';
@NgModule({
  declarations: [
    LayoutContainerComponent,

  ],
  imports: [
    CommonModule,
    VerticalModule,
    SharedModule,
    PipesModule,
  ],
  exports: [LayoutContainerComponent]
})

export class LayoutModule { }
