export const environment = {
  production: true,
 //Local Services
  //base_url: 'http://localhost:3001/back',

  //Development
  base_url_v2: 'https://dev-kite-backv2.j14host.com/back',
  //base_url: 'https://dev-kite-backv.j14host.com/back',

  //Production
  //base_url_v2: 'https://kite-back-main.j14host.com/back',
  base_url: 'https://kite-pro.com/back-dev',

  // External Services
  apiZapier: 'https://hooks.zapier.com/hooks/catch/11934680/bih759g/',
  apiPabbly: 'https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjMwNTY1MDYzMDA0MzE1MjY1NTUzMSI_3D_pc'
};
