import { Component, OnInit } from '@angular/core';
import { SettingService } from 'src/app/services/setting.service';
import { SidebarService } from 'src/app/services/sidebar.service';


declare function customInitFunctions();


@Component({
  selector: 'app-vertical-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class VerticalLayoutComponent implements OnInit {

  constructor( private settingsServices: SettingService,
               private sidebarService: SidebarService ) { }

ngOnInit(): void {
  customInitFunctions();
  this.sidebarService.cargarMenu();
}
}
