import { Validators } from '@angular/forms';


export const RecoveryPasswordFields = {
 user_email: ['', Validators.required],
 password: [''],
}
export const UpdatePasswordFileds = {
  password: ['', Validators.required],
  passwordRepeat: ['', Validators.required],
}
export interface EmailPwd {
email: string;
password: string;
}
