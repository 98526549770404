<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar" style="background-image:url(../assets/images/background/login-register.jpg);">
    <div class="login-box card">
        <div class="card-body py-0">

            <!-- ***Formulario de Login -->
            <form class="form-horizontal form-material v-position" id="loginform" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="login()">
                <a class="text-center db"><img src="../assets/images/kite-brand.png" alt="Home" class="img-fluid" /><br/></a>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input class="form-control" type="email" formControlName="email" placeholder="Email">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" formControlName="password" placeholder="Password">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary pull-left p-t-0">
                            <input id="checkbox-signup" type="checkbox" formControlName="remember" class="filled-in chk-col-light-blue">
                            <label for="checkbox-signup"> Remember me </label>
                        </div>
                        <a id="to-recover" class="text-dark pull-right" routerLink="/recoverypassword"><i class="fa fa-lock m-r-5"></i> <b>Forgot pwd?</b></a> </div>

                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Log In</button>
                    </div>
                </div>
                <!-- ***Boton Google Sign-in -->
                <!-- <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 mb-3 text-center" id="google-btn">


                        <div id="my-signin2"></div>

                    </div>
                </div> -->
 
            </form>


            <form class="form-horizontal" id="recoverform" action="index.html">
                <div class="form-group ">
                    <div class="col-xs-12">
                        <h3>Recover Password</h3>
                        <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" required="" placeholder="Email">
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
