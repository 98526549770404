import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './kt-autocomplete-input/pipes/filter.pipe';
import { HighlightDirective } from './kt-autocomplete-input/directives/highlight.directive';
import { FocusDirective } from './kt-autocomplete-input/directives/focus.directive';
import { NativeElementInjectDirective } from './kt-autocomplete-input/directives/native-element-inject.directive';
import { AutoCompleteInputComponent } from './kt-autocomplete-input/kt-autocomplete-input.component';



@NgModule({
  declarations: [
    AutoCompleteInputComponent,
    NativeElementInjectDirective,
    FocusDirective,
    HighlightDirective,
    FilterPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
		ReactiveFormsModule
  ],
  providers: [],
  exports: [
    AutoCompleteInputComponent,
    NativeElementInjectDirective,
    FocusDirective,
    HighlightDirective,
    FilterPipe
  ],
})
export class KiteModule { }
