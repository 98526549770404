import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EmailPwd, RecoveryPasswordFields, UpdatePasswordFileds } from '../../../constants/forms/recovery-password';
import { ApiRequest } from '../../presenter/interfaces/api.request';
import { AuthPresenter } from '../../presenter/auth.presenter';
import { IsRequestComplete } from '../../../interfaces/isrequest-complete';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiResult } from '../../presenter/interfaces/api.result';

@Component({
  selector: 'app-recoverypassword',
  templateUrl: './recoverypassword.component.html',
  styleUrls: ['./recoverypassword.component.scss']
})
export class RecoverypasswordComponent implements ApiResult, IsRequestComplete, OnInit {

private request: ApiRequest;

formRecoveryPassword: FormGroup;
formUpdatePassword: FormGroup;

formSubmitted = false;
formSubmittedPassword = false;

watchPassword = false;

isFormRecoveryPassword = true;
isFormUpdatePassword = false;

token: string ='';
uid: string ='';

constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private authPresenter: AuthPresenter,
              private router: Router,) {
  this.request = authPresenter;
  authPresenter.setRequestCompleteComponent(this);
  authPresenter.setComponent(this);
}

  ngOnInit() {
    this.formRecoveryPass();
    this.getFormUpdatePassword();
  }

  isRequestComplete(status: boolean, error?: HttpErrorResponse, res?:any): void {
      if(!status){
        this.toastr.error(error.error.msg);
        return
      }
      this.router.navigateByUrl('/login')
      this.toastr.success(res.msg)
  }
  isSendRecoveryKey(res: any, error?: HttpErrorResponse): void {
        if(error){
          this.toastr.error(error.error.msg);
          return
        }
        this.token = res?.token;
        this.uid = res?.uid;
        this.password.clearValidators();
        this.isFormRecoveryPassword = false;
        this.isFormUpdatePassword = true;
  }
  isRecoverKey(status: boolean, error?: HttpErrorResponse): void {
        if(!status){
          this.toastr.error(error.error.msg);
          return
        }
         this.toastr.success('Check your email');
         this.watchPassword = true;
         this.formSubmitted = false;
         this.password.setValidators([Validators.required]);
  }

  formRecoveryPass(){
    this.formRecoveryPassword = this.fb.group(RecoveryPasswordFields);
  }
  get userEmail() {
    return this.formRecoveryPassword.get('user_email');
  }
  get password() {
    return this.formRecoveryPassword.get('password');
  }
  getFormUpdatePassword(){
    this.formUpdatePassword = this.fb.group(UpdatePasswordFileds);
  }
  get passwordUpdate() {
    return this.formUpdatePassword.get('password');
  }
  get passwordNoValid() {
    const pass1 = this.formUpdatePassword.get('password').value;
    const pass2 = this.formUpdatePassword.get('passwordRepeat').value;
    return (pass1 === pass2) ? false : true;
  }
  sendEmail(){
    this.formSubmitted = true;
    if(this.formRecoveryPassword.valid){
      if(this.watchPassword){
        const data: EmailPwd = {
                                email: this.formRecoveryPassword.value.user_email,
                                password: this.formRecoveryPassword.value.password
                                };
        this.request.sendRecoveryKey(data);
      } else {
        this.request.recoverKey(this.userEmail.value);
      }
  }
  }
  sendPasswords(){
    this.formSubmittedPassword = true
    if(this.formUpdatePassword.valid){
      this.request.updatePaswordUser({password: this.formUpdatePassword.value.password, token: this.token, uid: this.uid});
    }
  }
}
