import { Validators } from '@angular/forms';


export const RegisterUserField = {
  name: ['', Validators.required],
  email: ['', [Validators.required, Validators.email]],
  password: ['', Validators.required],
  password2: ['', Validators.required],
  terminos: [true, Validators.required],
  signature: [''],
  signature_date: [''],
}
