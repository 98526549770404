<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar">

    <div class="login-box card">
        <div class="card-body boxForm">

            <!-- *** Formulario de Registro -->

            <form *ngIf="isFormRecoveryPassword" class="form-horizontal form-material" id="loginform" autocomplete="off" [formGroup]="formRecoveryPassword" (ngSubmit)="sendEmail()">
                <a class="text-center db"><img src="./assets/images/icon/staff.png" alt="Home" /><br/></a>
                <h3 class="box-title m-t-40 m-b-0">Recovery password</h3>

                <div class="form-group mt-2">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" formControlName="user_email" placeholder="Email">
                    </div>
                    <small *ngIf="(userEmail.invalid  && userEmail.touched) || (formSubmitted && userEmail.errors?.required)" class="text-danger animated fadeIn">* Email is required</small>
                </div>
                <div class="form-group" *ngIf="watchPassword">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" formControlName="password" placeholder="Key">
                    </div>
                    <small *ngIf="(password.invalid  && password.touched) || (formSubmitted && password.errors?.required)" class="text-danger animated fadeIn">* Key is required</small>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button *ngIf="!watchPassword" class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Send</button>
                        <button *ngIf="watchPassword" class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Access with KEY</button>

                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        Already have an account? <a routerLink="/login" class="text-info m-l-5"><b>Sign In</b></a>
                    </div>
                </div>
            </form>
            <!-- *** Formulario de cambiar contraseña -->

            <form *ngIf="isFormUpdatePassword" class="form-horizontal form-material" id="loginform" autocomplete="off" [formGroup]="formUpdatePassword" (ngSubmit)="sendPasswords()">
                <a class="text-center db"><img src="./assets/images/icon/staff.png" alt="Home" /><br/></a>
                <h3 class="box-title m-t-40 m-b-0">User password recovery</h3>

                <div class="form-group mt-2">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" formControlName="password" placeholder="Password">
                    </div>
                    <small *ngIf="(passwordUpdate.invalid  && passwordUpdate.touched) || (formSubmittedPassword && passwordUpdate.errors?.required)" class="text-danger animated fadeIn">* Password is required</small>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" formControlName="passwordRepeat" placeholder="Repeat password">
                    </div>
                    <small *ngIf="passwordNoValid" class="text-danger animated fadeIn">* Password different</small>
                </div>

                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Updated password</button>
                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        Already have an account? <a routerLink="/login" class="text-info m-l-5"><b>Sign In</b></a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
