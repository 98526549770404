<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar" style="background-image:url(../assets/images/background/login-register.jpg);">
    <div class="login-box card">
        <div class="card-body">

            <!-- *** Formulario de Registro -->

            <form class="form-horizontal form-material" id="loginform" autocomplete="off" [formGroup]="registerForm" (ngSubmit)="crearUsuario()">
                <a class="text-center db"><img src="./assets/images/icon/staff.png" alt="Home" /><br/></a>
                <h3 class="box-title m-t-40 m-b-0">Register Now</h3><small>Create your account and enjoy</small>

                <div class="form-group m-t-20">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" formControlName="name" placeholder="Name">
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" formControlName="email" placeholder="Email">
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" formControlName="password" placeholder="Password">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" formControlName="password2" placeholder="Confirm Password">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary p-t-0">
                            <input id="checkbox-signup" type="checkbox" formControlName="terminos">
                            <label for="checkbox-signup"> I agree to all <a href="">Terms</a></label>
                        </div>
                    </div>
                </div>

                <!-- ***Alerta por validacion del formulario -->
                <div class="row">
                    <div class="col text-danger">
                        <p *ngIf="campoNoValido('name')">* Name is required</p>
                        <p *ngIf="campoNoValido('email')">* Email in valid</p>
                        <p *ngIf="contrasenasNoValidas()">* Password required</p>
                        <p *ngIf="aceptarTerminos()">* Terms & Conditions</p>
                    </div>
                </div>



                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Sign Up</button>
                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        <p>Already have an account? <a routerLink="/login" class="text-info m-l-5"><b>Sign In</b></a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->