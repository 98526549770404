import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../../environments/environment.prod';
import { DomSanitizer } from '@angular/platform-browser';

const base_url = environment.base_url;

@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

    constructor(private sanitizer?: DomSanitizer) {}

  transform( img: string, tipo: 'users'|'areas'|'employees'|'orders', id?: string ): string {

    if(img && tipo == 'orders'){
      return `${ base_url }/fileuploads/${ tipo }/${ id }/${ img }`;
    }

    if ( !img ) {

      return `${ base_url }/upload/users/no-image`;

    }else if (img.includes('https')) {

      return this.sanitizer.bypassSecurityTrustResourceUrl(img) as string;

    }else if (img) {

      return `${ base_url }/upload/${ tipo }/${ img }`;

    } else {

      return `${ base_url }/upload/users/no-image`;

    }


    }






}
